import Anchor from "~/components/Anchor";
import Block, { BlockContent } from "~/components/Block";
import Spacer from "~/components/Spacer";
import Title from "~/components/Title";
import { getCommonSliceProps, getRichText } from "~/external/prismic";
import connectSlice from "~/lib/connectSlice";
import { parseFontStyle, parseFontWeight } from "~/styles/theme";

function TitleSlice(props) {
  const {
    spacingTop = 0,
    spacingBottom = 6,

    backgroundColor,
    color,
    animateIn = false,

    highlightColor,
    highlightOutline,
    anchorText,
    anchorPreText,
    anchorId,
    titleType,
    line1 = null,
    line2 = null,
    line3 = null,
    size = 1,
    spacing = 0,
    fontWeight = "400",
    fontStyle = "",
  } = props;

  // console.log('size', size, typeof size);

  return (
    <Block
      spacingTop={spacingTop}
      spacingBottom={spacingBottom}
      backgroundColor={backgroundColor}
      color={color}
    >
      <BlockContent>
        {anchorText && (
          <Anchor color={color} id={anchorId} pre={anchorPreText}>
            {anchorText}
          </Anchor>
        )}
        <Title
          highlightColor={highlightColor}
          highlightOutline={highlightOutline}
          color={color}
          type={titleType}
          line1={line1}
          line2={line2}
          line3={line3}
          size={size}
          animateIn={animateIn}
          fontWeight={fontWeight}
          fontStyle={fontStyle}
        />
        {spacing > 0 && <Spacer backgroundColor={backgroundColor} size={spacing} />}
      </BlockContent>
    </Block>
  );
}

export function mapSliceToProps(slice) {
  const { primary, items } = slice;

  const props = {
    ...getCommonSliceProps(slice),

    titleType: primary.title_type,
    line1: getRichText(primary.line_1), // .length > 0 && <RichText render={primary.line_1} />,
    line2: getRichText(primary.line_2), // .length > 0 && <RichText render={primary.line_2} />,
    line3: getRichText(primary.line_3), // .length > 0 && <RichText render={primary.line_3} />,
    spacing: primary.spacing || 0,
    size: primary.size || 1,

    fontWeight: parseFontWeight(primary.font_weight, "Normal"),
    fontStyle: parseFontStyle(primary.font_style, "Normal"),
  };
  return props;
}

export default connectSlice(mapSliceToProps)(TitleSlice);
