import React from "react";

// stolen from nextjs withApollo
function getComponentDisplayName(Component) {
  return Component.displayName || Component.name || "Unknown";
}

// returns a connect function kinda like react-redux's `connect`
// usage:
// connect(mapSliceToProps)(Component)
function connectSlice(mapSliceToProps) {
  return (Component) =>
    // return a class so we can set displayName?
    // stolen from nextjs withApollo
    class ConnectedComponent extends React.Component {
      static displayName = getComponentDisplayName(Component);

      render() {
        // if slice is provided, map the slice properties to props
        // (with the provided mapSliceToProps function)
        if (this.props.slice && mapSliceToProps) {
          return (
            <Component
              {...mapSliceToProps(this.props.slice, this.props.externalData)}
            />
          );
        }
        return <Component {...this.props} />;
      }
    };
}

export default connectSlice;
