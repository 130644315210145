import styled from "styled-components";
import Bullet from "~/components/Bullet";
import { vSpacing } from "~/styles/mixins";
import { rem } from "~/styles/theme";

const AnchorWrapper = styled.div`
  ${vSpacing};
  position: relative;
  color: ${(p) => p.color || p.theme.colors.dark};
  line-height: 0.75; /* truncate whitespace top and bottom to make dimensions fit with spacing. */
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${(p) => rem(`${p.size || 12}px`)};
  > * {
    margin-right: ${rem("5px")};
  }
  white-space: nowrap;
`;

const InvisibleAnchor = styled.div`
  position: relative;
`;

const AnchorTrigger = styled.div`
  display: block;
  position: absolute;
  top: -${(p) => p.theme.sizes.vBlockSpacing}rem;
  visibility: hidden;
`;

/**
 * NOTE: please use the `text` props if one [text] · [text]
 * Use spans if custom.
 */

function Anchor(props) {
  const { children, color, size, id, noBullet = false, pre } = props;

  // if text is empty, just have the trigger!
  if (!children) {
    return (
      <InvisibleAnchor>
        <AnchorTrigger className="anchor--trigger" id={id} />
      </InvisibleAnchor>
    );
  }

  return (
    <AnchorWrapper color={color} size={size} className="anchor">
      <AnchorTrigger className="anchor--trigger" id={id} />
      {!noBullet && (
        <Bullet size={5} style={{ marginRight: rem("7px") }} color={color} />
      )}{" "}
      {pre && (
        <>
          <span>{pre}</span> <span>·</span>
        </>
      )}
      <span style={{ fontWeight: "bold" }}>{children}</span>
    </AnchorWrapper>
  );
}

export default Anchor;
